// 操作按钮配置项
const operateList = vm => {
  return []
}

const baseColumn = vm => {
  return [
    {
      keyId: 1,
      title: '工单号',
      field: 'orderNo',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 2,
      title: '工地名称',
      field: 'projectName',
      width: 160,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      isSearchShow: true,
      selectOptions: [{
        value:'',
        label:'全部项目'
      }],
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 3,
      title: '工单状态',
      field: 'deleteFlag',
      width: 160,
      searchType: 'select',
      searchValue: '', //搜索绑定值
      renderType: 'status', //achnor,status,option
      showField: true,
      sortable: true, //是否排序
      textColor: '#e6a23c',
      copy: false, //是否拥有复制功能
      selectOptions: [
        {
          label: '已删除',
          value: 'DELETED'
        },
        {
          label: '正常',
          value: 'NORMAL'
        }
      ],
      enum: true,
      fieldOperateTag: {
        NORMAL: 'success',
        DELETED: 'warning'
      },
      fieldEnumValueList: {
        DELETED: '已删除',
        NORMAL: '正常'
      }
    },
    // {
    //   keyId: 4,
    //   title: '操作人',
    //   field: 'updateBy',
    //   width: 160,
    //   fieldType: null,
    //   searchType: 'input',
    //   searchValue: '',
    //   selectOptions: null,
    //   showField: true,
    //   enum: false,
    //   fieldEnumValueList: null,
    //   fieldOperateTag: null,
    //   sortable: false,
    //   multiField: false,
    //   fieldCanClick: false,
    //   filterable: false,
    //   textColor: null,
    //   fixed: null,
    //   renderType: null,
    //   copy: true
    // },
    {
      keyId: 5,
      title: '删除原因',
      field: 'exception',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 6,
      title: '删除时间',
      field: 'updated',
      width: 160,
      fieldType: null,
      searchType: 'daterange',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    // {
    //   keyId: 7,
    //   title: '管理员',
    //   field: 'createBy',
    //   width: 160,
    //   fieldType: null,
    //   searchType: 'input',
    //   searchValue: '',
    //   selectOptions: null,
    //   showField: true,
    //   enum: false,
    //   fieldEnumValueList: null,
    //   fieldOperateTag: null,
    //   sortable: false,
    //   multiField: false,
    //   fieldCanClick: false,
    //   filterable: false,
    //   textColor: null,
    //   fixed: null,
    //   renderType: null,
    //   copy: true
    // },
    {
      keyId: 8,
      title: '车牌号',
      field: 'plateNumber',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 9,
      title: '车类型',
      field: 'carTypeName',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 10,
      title: '车斗大小',
      field: 'bodySize',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 11,
      title: '司机',
      field: 'driverName',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 12,
      title: '车队',
      field: 'fleetName',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 13,
      title: '渣土类型',
      field: 'tlxName',
      width: 160,
      fieldType: null,
      searchType: 'daterange',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 14,
      title: '倒土方式',
      field: 'ztcName',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    // {
    //   keyId: 14,
    //   title: '入场放行日期',
    //   field: 'inReleaserDate',
    //   width: 160,
    //   fieldType: null,
    //   searchType: 'daterange',
    //   searchValue: '',
    //   selectOptions: null,
    //   showField: true,
    //   enum: false,
    //   fieldEnumValueList: null,
    //   fieldOperateTag: null,
    //   sortable: false,
    //   multiField: false,
    //   fieldCanClick: false,
    //   filterable: false,
    //   textColor: null,
    //   fixed: null,
    //   renderType: null,
    //   copy: true
    // },
    {
      keyId: 15,
      title: '出场放行时间',
      field: 'inReleaserTime',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    // {
    //   keyId: 16,
    //   title: '出场放行日期',
    //   field: 'outReleaserDate',
    //   width: 160,
    //   fieldType: null,
    //   searchType: 'daterange',
    //   searchValue: '',
    //   showField: true,
    //   enum: false,
    //   selectOptions: null,
    //   fieldEnumValueList: null,
    //   fieldOperateTag: null,
    //   sortable: false,
    //   multiField: false,
    //   fieldCanClick: false,
    //   filterable: false,
    //   textColor: null,
    //   fixed: null,
    //   renderType: null,
    //   copy: true
    // },
    {
      keyId: 17,
      title: '出场放行时间',
      field: 'outReleaserTime',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 18,
      title: '工地结算状态',
      field: 'workSettlementStatus',
      width: 160,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      showField: true,
      enum: true,
      selectOptions: [
        {
          label: '已结算',
          value: 'SETTLE'
        },
        {
          label: '未结算',
          value: 'NOT_SETTLE'
        }
      ],
      fieldOperateTag: {
        SETTLE: 'success',
        NOT_SETTLE: 'warning'
      },
      fieldEnumValueList: {
        SETTLE: '已结算',
        NOT_SETTLE: '未结算'
      },
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    // {
    //   keyId: 19,
    //   title: '工地结算日期',
    //   field: 'workSettlementDate',
    //   width: 160,
    //   fieldType: null,
    //   searchType: 'daterange',
    //   searchValue: '',
    //   selectOptions: null,
    //   showField: true,
    //   enum: false,
    //   fieldEnumValueList: null,
    //   fieldOperateTag: null,
    //   sortable: false,
    //   multiField: false,
    //   fieldCanClick: false,
    //   filterable: false,
    //   textColor: null,
    //   fixed: null,
    //   renderType: null,
    //   copy: true
    // },
    {
      keyId: 20,
      title: '工地结算时间',
      field: 'workSettlementTime',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 21,
      title: '渣土场结算状态',
      field: 'muckSettlementStatus',
      width: 160,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      showField: true,
      enum: true,
      selectOptions: [
        {
          label: '已结算',
          value: 'SETTLE'
        },
        {
          label: '未结算',
          value: 'NOT_SETTLE'
        }
      ],
      fieldOperateTag: {
        SETTLE: 'success',
        NOT_SETTLE: 'warning'
      },
      fieldEnumValueList: {
        SETTLE: '已结算',
        NOT_SETTLE: '未结算'
      },
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 22,
      title: '渣土场结算时间',
      field: 'muckSettlementTime',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 23,
      title: '班次',
      field: 'workType',
      width: 160,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      showField: true,
      enum: true,
      selectOptions: [
        {
          label: '白班',
          value: 'DAY_WORK'
        },
        {
          label: '夜班',
          value: 'NIGHT_WORK'
        },
        {
          label: '白晚班',
          value: 'OFF_WORK'
        }
      ],
      fieldOperateTag: {
        DAY_WORK: 'success',
        NIGHT_WORK: 'success',
        OFF_WORK: 'success'
      },
      fieldEnumValueList: {
        DAY_WORK: '白班',
        NIGHT_WORK: '夜班',
        OFF_WORK: '白晚班'
      },
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    // {
    //   keyId: 24,
    //   title: '入场车斗状态',
    //   field: 'inBodyStatus',
    //   width: 160,
    //   fieldType: null,
    //   searchType: 'select',
    //   searchValue: '',
    //   showField: true,
    //   enum: true,
    //   selectOptions: [
    //     {
    //       label: '已进场',
    //       value: 'IN'
    //     },
    //     {
    //       label: '未进场',
    //       value: 'NOT_IN'
    //     }
    //   ],
    //   fieldOperateTag: {
    //     IN: 'success',
    //     NOT_IN: 'warning'
    //   },
    //   fieldEnumValueList: {
    //     IN: '已进场',
    //     NOT_IN: '未进场'
    //   },
    //   sortable: false,
    //   multiField: false,
    //   fieldCanClick: false,
    //   filterable: false,
    //   textColor: null,
    //   fixed: null,
    //   renderType: 'status',
    //   copy: true
    // },
    // {
    //   keyId: 25,
    //   title: '出场车斗状态',
    //   field: 'outBodyStatus',
    //   width: 160,
    //   fieldType: null,
    //   searchType: 'select',
    //   searchValue: '',
    //   showField: true,
    //   enum: true,
    //   selectOptions: [
    //     {
    //       label: '已出场',
    //       value: 'OUT'
    //     },
    //     {
    //       label: '未出场',
    //       value: 'NOT_OUT'
    //     }
    //   ],
    //   fieldOperateTag: {
    //     OUT: 'success',
    //     NOT_OUT: 'warning'
    //   },
    //   fieldEnumValueList: {
    //     OUT: '已出场',
    //     NOT_OUT: '未出场'
    //   },
    //   sortable: false,
    //   multiField: false,
    //   fieldCanClick: false,
    //   filterable: false,
    //   textColor: null,
    //   fixed: null,
    //   renderType: 'status',
    //   copy: true
    // },
    {
      keyId: 26,
      title: '单价',
      field: 'price',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 27,
      title: '异常原因',
      field: 'exception',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 28,
      title: '入场车重',
      field: 'inWeight',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 29,
      title: '出场车重',
      field: 'name',
      width: 160,
      fieldType: null,
      searchType: 'outWeight',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 30,
      title: '净重',
      field: 'weight',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
  ]
}

// 表格列配置
const tableFields = vm => {
  return [
    {
      keyId: 1,
      title: '工单号',
      field: 'orderNo',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 2,
      title: '工地名称',
      field: 'projectName',
      width: 160,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      isSearchShow: true,
      selectOptions: [{
        value:'',
        label:'全部项目'
      }],
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 3,
      title: '工单状态',
      field: 'deleteFlag',
      width: 160,
      searchType: 'select',
      isSearchShow: false,
      searchValue: '', //搜索绑定值
      renderType: 'status', //achnor,status,option
      showField: true,
      sortable: true, //是否排序
      textColor: '#e6a23c',
      copy: false, //是否拥有复制功能
      selectOptions: [
        {
          label: '已删除',
          value: 'DELETED'
        },
        {
          label: '正常',
          value: 'NORMAL'
        }
      ],
      enum: true,
      fieldOperateTag: {
        NORMAL: 'success',
        DELETED: 'warning'
      },
      fieldEnumValueList: {
        DELETED: '已删除',
        NORMAL: '正常'
      }
    },
    // {
    //   keyId: 4,
    //   title: '操作人',
    //   field: 'updateBy',
    //   width: 160,
    //   fieldType: null,
    //   searchType: 'input',
    //   searchValue: '',
    //   selectOptions: null,
    //   showField: true,
    //   enum: false,
    //   fieldEnumValueList: null,
    //   fieldOperateTag: null,
    //   sortable: false,
    //   multiField: false,
    //   fieldCanClick: false,
    //   filterable: false,
    //   textColor: null,
    //   fixed: null,
    //   renderType: null,
    //   copy: true
    // },
    {
      keyId: 5,
      title: '删除原因',
      field: 'exception',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 6,
      title: '删除时间',
      field: 'updated',
      width: 160,
      fieldType: null,
      searchType: 'daterange',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    // {
    //   keyId: 7,
    //   title: '管理员',
    //   field: 'createBy',
    //   width: 160,
    //   fieldType: null,
    //   searchType: 'input',
    //   searchValue: '',
    //   selectOptions: null,
    //   showField: true,
    //   enum: false,
    //   fieldEnumValueList: null,
    //   fieldOperateTag: null,
    //   sortable: false,
    //   multiField: false,
    //   fieldCanClick: false,
    //   filterable: false,
    //   textColor: null,
    //   fixed: null,
    //   renderType: null,
    //   copy: true
    // },
    {
      keyId: 8,
      title: '车牌号',
      field: 'plateNumber',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 9,
      title: '车类型',
      field: 'carTypeName',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 10,
      title: '车斗大小',
      field: 'bodySize',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 11,
      title: '司机',
      field: 'driverName',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 12,
      title: '车队',
      field: 'fleetName',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 13,
      title: '渣土类型',
      field: 'tlxName',
      width: 160,
      fieldType: null,
      searchType: 'daterange',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 14,
      title: '倒土方式',
      field: 'ztcName',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    // {
    //   keyId: 14,
    //   title: '入场放行日期',
    //   field: 'inReleaserDate',
    //   width: 160,
    //   fieldType: null,
    //   searchType: 'daterange',
    //   searchValue: '',
    //   selectOptions: null,
    //   showField: true,
    //   enum: false,
    //   fieldEnumValueList: null,
    //   fieldOperateTag: null,
    //   sortable: false,
    //   multiField: false,
    //   fieldCanClick: false,
    //   filterable: false,
    //   textColor: null,
    //   fixed: null,
    //   renderType: null,
    //   copy: true
    // },
    {
      keyId: 15,
      title: '入场放行时间',
      field: 'inReleaserTime',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    // {
    //   keyId: 16,
    //   title: '出场放行日期',
    //   field: 'outReleaserDate',
    //   width: 160,
    //   fieldType: null,
    //   searchType: 'daterange',
    //   searchValue: '',
    //   showField: true,
    //   enum: false,
    //   selectOptions: null,
    //   fieldEnumValueList: null,
    //   fieldOperateTag: null,
    //   sortable: false,
    //   multiField: false,
    //   fieldCanClick: false,
    //   filterable: false,
    //   textColor: null,
    //   fixed: null,
    //   renderType: null,
    //   copy: true
    // },
    {
      keyId: 17,
      title: '出场放行时间',
      field: 'outReleaserTime',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 18,
      title: '工地结算状态',
      field: 'workSettlementStatus',
      width: 160,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      showField: true,
      enum: true,
      selectOptions: [
        {
          label: '已结算',
          value: 'SETTLE'
        },
        {
          label: '未结算',
          value: 'NOT_SETTLE'
        }
      ],
      fieldOperateTag: {
        SETTLE: 'success',
        NOT_SETTLE: 'warning'
      },
      fieldEnumValueList: {
        SETTLE: '已结算',
        NOT_SETTLE: '未结算'
      },
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    // {
    //   keyId: 19,
    //   title: '工地结算日期',
    //   field: 'workSettlementDate',
    //   width: 160,
    //   fieldType: null,
    //   searchType: 'daterange',
    //   searchValue: '',
    //   selectOptions: null,
    //   showField: true,
    //   enum: false,
    //   fieldEnumValueList: null,
    //   fieldOperateTag: null,
    //   sortable: false,
    //   multiField: false,
    //   fieldCanClick: false,
    //   filterable: false,
    //   textColor: null,
    //   fixed: null,
    //   renderType: null,
    //   copy: true
    // },
    {
      keyId: 20,
      title: '工地结算时间',
      field: 'workSettlementTime',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 21,
      title: '渣土场结算状态',
      field: 'muckSettlementStatus',
      width: 160,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      showField: true,
      enum: true,
      selectOptions: [
        {
          label: '已结算',
          value: 'SETTLE'
        },
        {
          label: '未结算',
          value: 'NOT_SETTLE'
        }
      ],
      fieldOperateTag: {
        SETTLE: 'success',
        NOT_SETTLE: 'warning'
      },
      fieldEnumValueList: {
        SETTLE: '已结算',
        NOT_SETTLE: '未结算'
      },
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 22,
      title: '渣土场结算时间',
      field: 'muckSettlementTime',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 23,
      title: '班次',
      field: 'workType',
      width: 160,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      showField: true,
      enum: true,
      selectOptions: [
        {
          label: '白班',
          value: 'DAY_WORK'
        },
        {
          label: '夜班',
          value: 'NIGHT_WORK'
        },
        {
          label: '白晚班',
          value: 'OFF_WORK'
        }
      ],
      fieldOperateTag: {
        DAY_WORK: 'success',
        NIGHT_WORK: 'success',
        OFF_WORK: 'success'
      },
      fieldEnumValueList: {
        DAY_WORK: '白班',
        NIGHT_WORK: '夜班',
        OFF_WORK: '白晚班'
      },
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    // {
    //   keyId: 24,
    //   title: '入场车斗状态',
    //   field: 'inBodyStatus',
    //   width: 160,
    //   fieldType: null,
    //   searchType: 'select',
    //   searchValue: '',
    //   showField: true,
    //   enum: true,
    //   selectOptions: [
    //     {
    //       label: '已进场',
    //       value: 'IN'
    //     },
    //     {
    //       label: '未进场',
    //       value: 'NOT_IN'
    //     }
    //   ],
    //   fieldOperateTag: {
    //     IN: 'success',
    //     NOT_IN: 'warning'
    //   },
    //   fieldEnumValueList: {
    //     IN: '已进场',
    //     NOT_IN: '未进场'
    //   },
    //   sortable: false,
    //   multiField: false,
    //   fieldCanClick: false,
    //   filterable: false,
    //   textColor: null,
    //   fixed: null,
    //   renderType: 'status',
    //   copy: true
    // },
    // {
    //   keyId: 25,
    //   title: '出场车斗状态',
    //   field: 'outBodyStatus',
    //   width: 160,
    //   fieldType: null,
    //   searchType: 'select',
    //   searchValue: '',
    //   showField: true,
    //   enum: true,
    //   selectOptions: [
    //     {
    //       label: '已出场',
    //       value: 'OUT'
    //     },
    //     {
    //       label: '未出场',
    //       value: 'NOT_OUT'
    //     }
    //   ],
    //   fieldOperateTag: {
    //     OUT: 'success',
    //     NOT_OUT: 'warning'
    //   },
    //   fieldEnumValueList: {
    //     OUT: '已出场',
    //     NOT_OUT: '未出场'
    //   },
    //   sortable: false,
    //   multiField: false,
    //   fieldCanClick: false,
    //   filterable: false,
    //   textColor: null,
    //   fixed: null,
    //   renderType: 'status',
    //   copy: true
    // },
    {
      keyId: 26,
      title: '单价',
      field: 'price',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 27,
      title: '异常原因',
      field: 'exception',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 28,
      title: '入场车重',
      field: 'inWeight',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 29,
      title: '出场车重',
      field: 'name',
      width: 160,
      fieldType: null,
      searchType: 'outWeight',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 30,
      title: '净重',
      field: 'weight',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
  ]
}

// 搜索字段配置项
const searchFields = vm => {
  return [
    {
      keyId: 1,
      title: '删除时间',
      field: 'updated',
      width: 160,
      fieldType: null,
      searchType: 'daterange',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 2,
      title: '工单号',
      field: 'orderNo',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 3,
      title: '工地名称',
      field: 'projectName',
      width: 160,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      isSearchShow: false,
      selectOptions: [{
        value:'',
        label:'全部项目'
      }],
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    }
  ]
}

export default {
  operateList,
  searchFields,
  tableFields,
  baseColumn
}
