<template>
  <div style="height: 100%; width: 100%">
    <search-table
      ref="searchTable"
      labelWidth="70px"
      :loading="listLoading"
      :searchFields="searchFields"
      :autoBtnList="autoBtnList"
      :colSize="colSize"
      :total="total"
      :tableData="dataList"
      :tableColumns="tableColumns"
      :baseColumns="baseColumns"
      :sortFields="sortFields"
      :checkboxColumn="true"
      :hasPagination="true"
      :showFooter="true"
      :showSetting="true"
      :showColumnSearch="true"
      :showSortable="true"
      :tableSummary="tableSummary"
      :proxyParams="proxyParams"
      :optionColumn="optionColumn"
      :selectTableSummary="selectTableSummary"
      @saveTableField="saveTableField"
      @saveSearchField="saveSearchField"
      @saveSortField="saveSortField"
      @getCheckedData="getCheckedData"
      @getTableData="queryList"
      @toDetailHandle="toDetailHandle"
      @recoverHandle="recoverHandle"
    >
    </search-table>
    <el-drawer :visible.sync="orderDetailVisible" direction="rtl" size="80%">
      <div style="line-height: 40px; color: #000; font-weight: bold" slot="title">
        {{ detailTitle }}
      </div>
      <project-order-detail v-if="detailRow !== null" :detailRow="detailRow" />
    </el-drawer>
  </div>
</template>
<script>
import { projectList } from '@/api/stt'
import { orderRecoverList, orderDetail, resumeSingleOrder } from '@/api/order'
import SearchTable from '@/components/LcSearchTable'
import config from '@/const/projectOrderRecover/index.js'
import { formatDate } from '@/utils/time'
import { columnSetting } from '@/api/common'
import column from '@/mixins/column.js'
import ProjectOrderDetail from './projectOrderDetail'
export default {
  components: {
    SearchTable,
    ProjectOrderDetail
  },
  mixins: [column],
  data: function () {
    return {
      total: 0,
      summary: {},
      projectName: '',
      projectId: '',
      orderDetailVisible: false,
      editCount: 0, //批量操作修改条数,
      dataList: [],
      bossProList: [],
      listLoading: false,
      baseColumn: [], //接口请求的表格配置
      baseColumns: [],
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 3, //用户设置展示列比例
      selectTableSummary: config.selectTableSummary, // 需要选中合计的字段
      sortFields: [], // 用户自定义的排序设置
      formParams: {}, // 当前查询的参数
      optionColumn: {
        title: '操作',
        width: 180,
        fixed: 'right',
        btnList: [
          {
            title: '查看详情',
            fn: 'toDetailHandle'
          },
          {
            title: '恢复',
            fn: 'recoverHandle'
          }
        ]
      },
      detailRow: {},
      detailTitle: '',
      lastBtnDisabled: false,
      nextBtnDisabled: false,
      curentIndex: 0, //当前切换到的单号索引
      path: '' // 更新字段名显示接口路径
    }
  },
  created() {
    this.projectId = this.$route.query.id
    this.projectName = this.$route.query.name
    this.queryList()
    this.searchFields = config.searchFields(this)
    this.baseColumns = config.tableFields(this)
    this.tableColumns = config.tableFields(this)
  },
  computed: {
    // 操作按钮
    autoBtnList() {
      return null
    }
  },
  activated() {
    this.projectId = this.$route.query.id
    this.projectName = this.$route.query.name
    this.getProjectList()
    this.queryList()
  },
  methods: {
    // 获取该老板所有的工地
    async getProjectList() {
      projectList().then(res => {
        let arr = [{ value: '', label: '全部项目' }]
        res.data.forEach(item => {
          arr.push({ value: item.name, label: item.name })
        })
        this.bossProList = arr
      })
    },
    // 恢复工单
    recoverHandle(row) {
      resumeSingleOrder({ id: row.id }).then(res => {
        if (res.code === 200) {
          this.$message.success('恢复工单【' + row.orderNo + '成功!')
          this.queryList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 查询
    async queryList(params) {
      if (params) {
        this.formParams = params
      } else {
        params = this.formParams
      }
      params['deleteFlag'] = 'DELETED'
      this.listLoading = true
      // this.baseColumns = config.baseColumn(this)
      const res = await orderRecoverList({ ...params })
      if (res.code === 0) {
        this.dataList = res.data.items
        this.total = res.data.total * 1
        this.tableSummary = res.data.summary
        this.baseColumns = config.baseColumn(this)
        // this.baseColumns = res.data.tableSettingFields || config.baseColumn(this)
        this.path = res.data.path || this.path
        this.colSize = res.data.colSize
        // this.initConfig()
      }
      this.searchFields.forEach(item => {
        if (item.field === 'projectName') {
          item.selectOptions = this.bossProList
        }
      })
      this.listLoading = false
    },
    toDetailHandle(row) {
      console.log(row)
      orderDetail({ id: row.id }).then(res => {
        this.detailRow = res.data
        this.detailTitle = res.data.orderNo + '详情'
        this.orderDetailVisible = true
      })
    },
    initConfig() {
      this.tableColumns = this.baseColumns.filter(v => v.showField)
      this.searchFields = this.baseColumns.filter(v => v.isSearchShow)
      this.sortFields = this.tableColumns
        .filter(v => v.sortable)
        .reduce((prev, item) => {
          // if(item.sortList) prev = prev.concat(item.sortList)
          prev.push({
            title: `${item.title}升序`,
            field: `${item.field}-asc`
          })
          prev.push({
            title: `${item.title}降序`,
            field: `${item.field}-desc`
          })
          return prev
        }, [])
    },
    // 时间格式化
    dateFormat(data) {
      return formatDate(data)
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      this.tableColumns = fields
      try {
        const res = await columnSetting({
          path: this.path,
          // searchFields: this.searchFields,
          tableFields: fields
        })
        this.getTableData()
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {
      // console.log(fields, colSize)
      this.colSize = colSize
      this.searchFields = fields
      try {
        const res = await columnSetting({
          path: this.path,
          searchFields: fields,
          // tableFields: this.tableColumns,
          colSize
        })
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改排序字段配置
    async saveSortField(fields) {
      this.sortFields = fields
    },
    // 处理数据
    proxyParams(params) {
      console.log(params)
    },
    getCheckedData(data) {
      this.selectTableData = data
    },
    //获取表格头配置
    async getTableField() {},
    //获取搜索字段配置
    async getSearchField() {}
    //重置表单
  }
}
</script>
<style scoped>
.module-title {
  margin-top: 10px;
  border-left: solid;
  border-left-width: 7px;
  color: rgb(29, 106, 208);
  padding-left: 5px;
  font-size: 16px;
  font-weight: bold;
}
.row-content {
  display: flex;
  width: 100%;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  color: rgb(72, 70, 70);
}
.left,
.right {
  margin-left: 10px;
  width: 280px;
}
.middle {
  flex: 1;
}
.ye-color {
  padding-left: 10px;
  color: rgb(236, 109, 12);
}
.span-v {
  padding-left: 10px;
}
.img {
  width: 200px;
  border-radius: 4px;
  height: 200px;
}
</style>
